<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="false">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="货品名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="条形码">
              <a-input v-model="queryParam.barcode" placeholder="请输入完整的编码" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="货品类型">
              <a-select
                v-model="queryParam.goods_type"
                placeholder="选择类型"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allow-clear
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_type">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 10px"
        title="消费税"
        :sub-title="'条形码：' + this.$route.query.barcode"
        @back="() => $router.go(-1)"
      >
      </a-page-header>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <div slot="rate" slot-scope="text">
        {{ (text * 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-form
      ref="editModal"
      v-if="edit_visible"
      :visible.sync="edit_visible"
      :loading="editConfirmLoading"
      :model="edit_mdl"
      @cancel="handleEditCancel"
      @ok="handleEditOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/consumption_tax_level/modules/CreateForm'
import EditForm from '@/views/c-wms/consumption_tax_level/modules/EditForm'
import { consumption_tax_level_create, consumption_tax_level_delete, consumption_tax_level_list, consumption_tax_level_update } from '@/api/c_wms_consumption_tax_level'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      visible: false,
      // 创建窗口控制
      mdl: {},
      edit_mdl: {},
      edit_visible: false,
      editConfirmLoading: false,
      // 创建窗口控制
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'serial',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '最小值',
          align: 'center',
          dataIndex: 'min_price',
          scopedSlots: { customRender: 'money_render' },
          ellipsis: true
        },
        {
          title: '最大值',
          align: 'center',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'max_price'
        },
        {
          title: '比例(%)',
          align: 'center',
          ellipsis: true,
          dataIndex: 'consumption_tax_rate',
          scopedSlots: { customRender: 'rate' }
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return consumption_tax_level_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
    this.queryParam.barcode = this.$route.query.barcode
    if (this.queryParam.barcode) {
      this.mdl.barcode = this.queryParam.barcode
    }
  },
  methods: {
    setCurrentPage () {
      const page = sessionStorage.getItem('CurrentPage')
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        }
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEditCancel () {
      this.edit_visible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.edit_visible = true
      this.edit_mdl = record
      console.log(this.edit_mdl)
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onEdit', record)
    },
    handleDelete (record) {
      console.log(record)
      consumption_tax_level_delete(record.id).then((res) => {
        console.log(res)
        if (res.success) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.barcode = this.$route.query.barcode
          values.min_price = Math.round(values.min_price * 100)
          values.max_price = Math.round(values.max_price * 100)
          values.consumption_tax_rate = values.consumption_tax_rate / 100
          console.log('提交的数据', values)
          consumption_tax_level_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditOk () {
      const form = this.$refs.editModal.form
      this.editConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('提交的数据', values)
          values.barcode = this.$route.query.barcode
          values.min_price = Math.round(values.min_price * 100)
          values.max_price = Math.round(values.max_price * 100)
          values.consumption_tax_rate = values.consumption_tax_rate / 100
          consumption_tax_level_update(values, this.edit_mdl.id).then(res => {
            this.edit_visible = false
            this.editConfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).catch((err) => {
              console.log(err)
              this.editConfirmLoading = false
            })
        } else {
          this.editConfirmLoading = false
        }
      })
    }
  }
}
</script>
