<template>
  <a-modal
    title="编辑"
    :width="480"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="最小值" >
              <a-input-number style="width: 100%" :precision="2" v-decorator="['min_price', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="最大值" >
              <a-input-number style="width: 100%" :precision="2" v-decorator="['max_price', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="比例" >
              <a-input-number style="width: 100%" :min="0" v-decorator="['consumption_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['min_price', 'max_price', 'consumption_tax_rate']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 当 model 发生改变时，为表单设置值
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.model)
    })
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        formData.max_price = (formData.max_price / 100).toFixed(2)
        formData.min_price = (formData.min_price / 100).toFixed(2)
        formData.consumption_tax_rate = formData.consumption_tax_rate * 100
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
