import request from '@/utils/request'
const requestApi = {
  wms_consumption_tax_level_list: '/wms/warehouse/consumption_tax_level/',
  wms_consumption_tax_level_create: '/wms/warehouse/consumption_tax_level/',
  wms_consumption_tax_level_update: '/wms/warehouse/consumption_tax_level/',
  wms_consumption_tax_level_delete: '/wms/warehouse/consumption_tax_level/'
}

export function consumption_tax_level_list (query) {
  return request({
    url: requestApi.wms_consumption_tax_level_list,
    method: 'get',
    params: query
  })
}

export function consumption_tax_level_create (data) {
  return request({
    url: requestApi.wms_consumption_tax_level_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function consumption_tax_level_update (data, consumption_tax_level_id) {
  return request({
    url: requestApi.wms_consumption_tax_level_update + consumption_tax_level_id + '/',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}
export function consumption_tax_level_delete (consumption_tax_level_id) {
  return request({
    url: requestApi.wms_consumption_tax_level_delete + consumption_tax_level_id + '/',
    method: 'delete'
  })
}
